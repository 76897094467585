import ApiService from './ApiService'
import axios from 'axios'

export async function apiSignIn(data) {
    var userName = data.userName;
    var password = data.password;

    const regUser = await axios.post(`https://leetintelligence.com:3013/farmer/v1/login/farmer-daas`, {
        data: {
            email: userName,
            password,
        },
    })

    return regUser;

    // return {
    //     data: {
    //         user: {
    //             id: '21',
    //             avatar: '/img/avatars/thumb-1.jpg',
    //             userName: 'leet-user',
    //             email: '',
    //             authority: ['user'],
    //             password: 'Leet@dev-demo54138924',
    //             accountUserName: '',
    //         },
    //         token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
    //     }
    // }
    // return ApiService.fetchData({
    //     url: '/sign-in',
    //     method: 'post',
    //     data,
    // })
}

export async function apiSignUp(data) {
    const { userName, password, email } = data;

    const regUser = await axios.post(`https://leetintelligence.com:3013/farmer/v1/register/farmer-daas`, {
        data: {
            userName,
            email,
            password,
        },
    });

    return regUser;

    // return {
    //     data: {
    //         user: {
    //             id: '21',
    //             avatar: '/img/avatars/thumb-1.jpg',
    //             userName: userName,
    //             email: email,
    //             authority: ['user'],
    //             password: 'Leet@dev-demo54138924',
    //             accountUserName: email,
    //         },
    //         token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
    //     }
    // }
    // return ApiService.fetchData({
    //     url: '/sign-up',
    //     method: 'post',
    //     data,
    // })
}

export async function apiSignOut(data) {
    return true
    // return ApiService.fetchData({
    //     url: '/sign-out',
    //     method: 'post',
    //     data,
    // })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
